import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import truncate from "lodash/truncate";
import { removeSnackbar, snackbarSelector } from "./snackbarReducer";
import { NotificationBlock } from "./Notification";

type Key = string | number;
let displayed: Key[] = [];
const storeDisplayed = (id: Key) => {
    displayed = [...displayed, id];
};
const removeDisplayed = (id) => {
    displayed = displayed.filter((key) => id !== key);
};

export function Notifier() {
    const dispatch = useDispatch();
    const { notifications } = useSelector(snackbarSelector);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    useEffect(() => {
        notifications.forEach(
            ({
                key,
                message,
                options = { onClose: undefined },
                dismissed = false,
                title,
                reload = false,
            }) => {
                if (dismissed) {
                    // dismiss snackbar using notistack
                    closeSnackbar(key);
                    return;
                }

                // do nothing if snackbar is already displayed
                if (displayed.includes(key)) return;

                // display snackbar using notistack
                enqueueSnackbar(
                    truncate(message, {
                        length: 1255,
                    }),
                    {
                        key,
                        ...options,
                        onClose: (event, reason, myKey) => {
                            if (options.onClose) {
                                options.onClose(event, reason, myKey);
                            }
                        },
                        onExited: (event, myKey) => {
                            // remove this snackbar from redux store
                            dispatch(removeSnackbar(myKey));
                            removeDisplayed(myKey);
                        },
                        content: (snackbarKey, snackbarMessage) =>
                            NotificationBlock({
                                key: snackbarKey,
                                message: snackbarMessage,
                                title: title || options.variant,
                                reload,
                                callback: closeSnackbar,
                            }),
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "right",
                        },
                        persist: options.persist,
                    },
                );

                // keep track of snackbars that we've displayed
                storeDisplayed(key);
            },
        );
    }, [notifications, enqueueSnackbar, closeSnackbar, dispatch]);

    return null;
}
