import { useState, useEffect } from "react";
import Router from "next/router";
import { Box, Button } from "@mui/material";
import { SnackbarMessage } from "notistack";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { styled } from "@mui/material/styles";
import Ic24InformationIcon from "~/features/icon/ic24_information.svg";
import { CloseIcon } from "~/features/icon/CloseIcon";
import { BodyS } from "~/features/typography";
import { fontFamilies, colors } from "~/theme";

const fontFamily = fontFamilies.hurme2;

const Container = styled(Box)`
    background-color: ${colors.black};
    width: 352px;
    border-radius: 10px;
    padding: 14px 17px;
`;
const NotificationOuterContainer = styled(Box)`
    display: flex;
`;
const InfoContainer = styled(Box)`
    width: 24px;
`;
const NotificationContainer = styled(Box)`
    margin-left: 7px;
    width: 304px;
`;
const TitleContainer = styled("b")`
    font-size: 14px;
    font-family: ${fontFamily};
    color: ${colors.white};
`;
const Title = styled("div")`
    display: inline-block;
    ::first-letter {
        text-transform: capitalize;
    }
`;
const ElapsedTime = styled("span")`
    opacity: 0.5;
    float: right;
    padding-right: 5px;
    padding-top: 5px;
    font-family: ${fontFamily};
    color: ${colors.white};
    font-size: 12px;
`;
const Message = styled("div")`
    padding-top: 10px;
    font-family: ${fontFamily};
    color: ${colors.white};
    font-size: 12px;
    line-height: 15.26px;
`;
const ReloadButtonContainer = styled(Box)`
    left: 210px;
    position: relative;
    padding-top: 10px;
    color: ${colors.white};
    line-height: 15.26px;
`;
const ReloadButton = styled(Button)`
    border-radius: 10px;
    background-color: ${colors.white};
    font-family: "Hurme hurmegeometricsans2", "Fira sans", sans-serif;
    font-size: 14px;
    text-transform: capitalize;
`;
const CloseIconButton = styled(CloseIcon)`
    top: -5px;
    position: relative;
    width: 24px;
    height: 24px;
    cursor: pointer;
    color: lightgray;
    :hover {
        color: ${colors.white};
    }
`;

export type NotificationBlockProps = {
    key?: string | number;
    title?: string;
    message: SnackbarMessage;
    callback?: Function;
    reload: Boolean;
};

export const NotificationBlock = ({
    key,
    title,
    message,
    reload,
    callback,
}: NotificationBlockProps) => {
    const [created] = useState(new Date());
    const [elapsed, setElapsed] = useState("");
    useEffect(() => {
        const interval = setInterval(() => {
            const timePeriod = formatDistanceToNow(created);
            const timeAgo =
                timePeriod !== "less than a minute" ? `${timePeriod} ago` : "";
            setElapsed(timeAgo);
        }, 1000);
        return () => clearInterval(interval);
    }, [created]);

    return (
        <Container id={key ? key.toString() : new Date().toString()}>
            <NotificationOuterContainer>
                <InfoContainer>
                    <Ic24InformationIcon />
                </InfoContainer>
                <NotificationContainer>
                    <BodyS>
                        <span>
                            <TitleContainer>
                                {title ? <Title>{title}</Title> : <>&nbsp;</>}
                            </TitleContainer>
                        </span>
                        <ElapsedTime>{elapsed}</ElapsedTime>
                    </BodyS>
                    <BodyS>
                        <Message>{message}</Message>
                    </BodyS>
                    {reload && (
                        <ReloadButtonContainer>
                            <ReloadButton
                                variant="contained"
                                color="primary"
                                onClick={Router.reload}
                            >
                                Reload
                            </ReloadButton>
                        </ReloadButtonContainer>
                    )}
                </NotificationContainer>
                <Box>
                    <CloseIconButton
                        onClick={() => callback && callback(key)}
                    />
                </Box>
            </NotificationOuterContainer>
        </Container>
    );
};
